import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
        this.chartReference = React.createRef();
    }

    componentDidMount() {
        //console.log(this.props.data); // returns a Chart.js instance reference

    }

    render() {
        return (<Doughnut style={{width:"100%", height:"100%"}} ref={this.chartReference} data={this.props.data} options={{
            color: "#FFFFFF",
            plugins: {
            legend: {
                labels: {
                     fontColor: "#FFFFFF"
                    }
                 }
        }}} />)
    }
}