import { Button, Overlay, Popover, Form, Alert } from 'react-bootstrap'
import React, { useState, useRef } from 'react'

export default function ResetPassword () {
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const ref = useRef(null)

  const handleClick = event => {
    setShow(!show)
    setTarget(event.target)
  }

  function validateForm () {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function handleResetPassword (e) {
    e.preventDefault()
    var headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    var raw = JSON.stringify({ email: email })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: raw,
      redirect: 'follow'
    }

    fetch(window.API_URL + 'users/password/reset', requestOptions)
      .then(response => response.text())
      .catch(error => console.log('error', error))

    setShow(false)
    setMessage(
      'If there is a valid account associated with this email address, an email will be sent to it'
    )
    setTimeout(() => {
      setMessage('')
    }, 5000)
  }

  return (
    <div ref={ref} style={{textAlign:"center"}}>
      <Button
        style={{ backgroundColor: 'transparent', border: '0' }}
        block={"true"}
        size='lg'
        onClick={handleClick}
      >
        Reset Password
      </Button>
      {message && (
        <div>
          <br />
          <Alert className='text-center' variant='success'>
            {' '}
            {message}{' '}
          </Alert>
        </div>
      )}
      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref.current}
        containerPadding={20}
      >
        <Popover id='popover-contained' style={{ backgroundColor: '#222222' }}>
          <Form size='lg' onSubmit={handleResetPassword}>
            <Form.Group size='lg' controlId='email'>
              <Form.Control
                placeholder='Enter Email...'
                style={{
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  backgroundColor: '#A0A0A0',
                  borderColor: '#A0A0A0'
                }}
                required
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              style={{
                boxShadow:
                  'box-shadow: 0px 0px 0px 0.2rem  rgba(169,171,45,0.7);',
                borderColor: '#A9AB2D',
                backgroundColor: '#A9AB2D'
              }}
              block={"true"}
              size='lg'
              type='submit'
              disabled={!validateForm()}
            >
              Reset Password
            </Button>
          </Form>
        </Popover>
      </Overlay>
    </div>
  )
}
