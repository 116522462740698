"use strict";

exports.__esModule = true;
exports.default = void 0;

function endOfDecade(date) {
  date = new Date(date);
  date.setFullYear(date.getFullYear() + 10);
  date.setMilliseconds(date.getMilliseconds() - 1);
  return date;
}

function endOfCentury(date) {
  date = new Date(date);
  date.setFullYear(date.getFullYear() + 100);
  date.setMilliseconds(date.getMilliseconds() - 1);
  return date;
}

class MomentLocalizer {
  constructor(moment) {
    this.m = moment;
    this.localeData = this.m.localeData();
  }

  toFormattedParts(date, format) {
    const m = this.m(date);
    return Object.keys(format).map(type => ({
      type,
      value: type === 'literal' ? format[type] : m.format(format[type])
    }));
  }

  date(date, format) {
    return this.m(date).format(format || 'L');
  }

  time(date, format) {
    return this.m(date).format(format || 'LT');
  }

  datetime(date, format) {
    return this.m(date).format(format || 'lll');
  }

  header(date, format) {
    return this.m(date).format(format || 'MMM YYYY');
  }

  weekday(date, format) {
    return this.m(date).format(format || 'dd');
  }

  dayOfMonth(date, format) {
    return this.m(date).format(format || 'DD');
  }

  month(date, format) {
    return this.m(date).format(format || 'MMM');
  }

  year(date, format) {
    return this.m(date).format(format || 'YYYY');
  }

  decade(date, format) {
    return format ? this.m(date).format(format) : `${this.m(date).format('YYYY')} - ${this.m(endOfDecade(date)).format('YYYY')}`;
  }

  century(date, format) {
    return format ? this.m(date).format(format) : `${this.m(date).format('YYYY')} - ${this.m(endOfCentury(date)).format('YYYY')}`;
  }

  firstOfWeek() {
    return this.localeData.firstDayOfWeek();
  }

  parse(value, format) {
    if (!value) return null;
    const m = this.m(value, format);
    return m.isValid() ? m.toDate() : null;
  }

}

exports.default = MomentLocalizer;
