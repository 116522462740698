import { Button, Form, Alert, Row, Col, Container, Card } from "react-bootstrap";
import React, { useState } from "react";

export default function ChangePassword() {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [cNewPassword, setCheckNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    function validateForm() {
        return newPassword === cNewPassword;
    }

    function handleChangePassword(e) {
        e.preventDefault();

        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");

        var raw = JSON.stringify({ "old_password": oldPassword, "new_password": newPassword });

        var requestOptions = {
            method: 'PATCH',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        var response = fetch(window.API_URL + "users/me/password", requestOptions);

        response.then(data=> { 
            if (data.status === 401) {
                setMessage("Incorrect Password");
                setMessageType("danger");
            }
            else {
                setMessage("Password Changed");
                setMessageType("success");
                setOldPassword("");
                setNewPassword("");
                setCheckNewPassword("");
            }
        });

        setTimeout(() => {
            setMessage("");
        }, 5000);
    }

    return (
        <Row size="lg" className="Login" >
            <Form size="lg" onSubmit={handleChangePassword}>
                <Form.Group size="lg" controlId="oldPassword">
                    <Form.Label style={{ color: "#FFFFFF" }}>Old Password</Form.Label>
                    <Form.Control
                        placeholder="Enter Old Password..."
                        style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
                        required
                        autoFocus
                        autoComplete="off"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="newPassword">
                    <Form.Label style={{ color: "#FFFFFF" }}>New Password</Form.Label>
                    <Form.Control
                        placeholder="Enter New Password..."
                        style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
                        required
                        autoComplete="off"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="checkNewPassword">
                    <Form.Label style={{ color: "#FFFFFF" }}>Confirm New Password</Form.Label>
                    <Form.Control
                        placeholder="Confirm New Password..."
                        style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
                        required
                        autoComplete="off"
                        type="password"
                        value={cNewPassword}
                        onChange={(e) => setCheckNewPassword(e.target.value)}
                    />
                </Form.Group>
                <br />
                <Row className="justify-content-md-center">
                    <Col >
                        <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" type="submit" disabled={!validateForm()}>
                            Change Password
                    </Button>
                    </Col>
                </Row>
                {
                    message &&
                    <div>
                        <br />
                        <Alert className="text-center" variant={messageType}> {message} </Alert>
                    </div>
                }
            </Form>
        </Row>
    )
}