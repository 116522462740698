import React, { useState } from 'react'
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    Row,
    Table as BSTable,
    Image,
    Accordion,
    Card,
    useAccordionButton
} from 'react-bootstrap'
import {
    useFilters,
    usePagination,
    useTable,
    useSortBy,
    defaultColumn
} from 'react-table'
import {
    BiDownArrowAlt,
    BiFilterAlt,
    BiMinus,
    BiUpArrowAlt
} from 'react-icons/bi'
import './table.css'
import left from '../content/chevron_left-white-18dp.svg'
import right from '../content/chevron_right-white-18dp.svg'
import leftend from '../content/first_page-white-18dp.svg'
import rightend from '../content/last_page-white-18dp.svg'

export default function SessionTable({
    columns,
    data,
    hideSearch = false,
    reloadHandle
}) {
    const [filterInput, setFilterInput] = useState('')
    const [timelineName, setTimelineName] = useState('')
    const [id, setId] = useState('')
    const [modelName, setModelName] = useState('')
    const [usersfullName, setUsersfullName] = useState('')
    const [sessionCreated, setSessionCreated] = useState('')
    const [sessionClosed, setSessionClosed] = useState('')
    const [grade, setGrade] = useState('')
    const [note, setNote] = useState('')
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
        setModelName('')
        setUsersfullName('')
        setSessionCreated('')
        setSessionClosed('')
        setGrade('')
        setNote('')
    }

    const handleShow = () => setShow(true)

    const handleFilterChange = e => {
        const value = e.target.value || undefined
        setFilter('usersfullname', value)
        setFilterInput(value)
    }

    const handleDoubleClick = e => {
        var sessionid = data.filter(
            f => f.id == e.target.parentNode.firstElementChild.innerHTML
        )[0].id
        getSessionData(sessionid)
        handleShow()
    }

    const handleSubmit = e => {
        e.preventDefault()
        var token = JSON.parse(sessionStorage.getItem('access_token'))?.access_token
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Accept', 'application/json')

        var raw = JSON.stringify({
            id: id,
            grade: grade,
            note: note
        })

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: raw,
            redirect: 'follow'
        }

        fetch(window.API_URL + 'sessions/dashboard', requestOptions)
            .then(response => response.json())
            .then(result => {
                handleClose()
                reloadHandle()
            })
    }

    const getSessionData = sessionid => {
        var token = JSON.parse(sessionStorage.getItem('access_token'))?.access_token
        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + token)
        headers.append('Accept', 'application/json')
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }

        fetch(window.API_URL + 'sessions/dashboard/' + sessionid, requestOptions)
            .then(response => response.json())
            .then(result => {
                setId(result.id)
                setTimelineName(result.timelinename)
                setModelName(result.modelname)
                setUsersfullName(result.usersfullname)
                setSessionCreated(result.sessioncreated)
                setSessionClosed(result.sessionclosed)
                setGrade(result.grade)
                setNote(result.note)
            })
    }

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    )
    // Define a default UI for filtering
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter }
    }) {
        const count = preFilteredRows.length

        return (
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
            />
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                pageSize: 10,
                hiddenColumns: ['sessionclosed'], //, "authkey"]
                sortBy: [
                    {
                        id: 'sessioncreated',
                        desc: true
                    }
                ]
            }
        },
        useFilters,
        useSortBy,
        usePagination
    )
    function searchField() {
        if (!hideSearch)
            return (
                <InputGroup>
                    <FormControl
                        value={filterInput}
                        onChange={handleFilterChange}
                        placeholder={"Search by User's Name"}
                    />
                </InputGroup>
            )
    }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {});
      
        return (
            <BiFilterAlt onClick={decoratedOnClick} style={{cursor: "pointer"}}/>
        );
      }

    return (
        <>
            <Row
                className='tablerow justify-content-mg-center h-100'
                style={{ paddingBottom: '0px' }}
            >
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton closeVariant='white'>
                        <Modal.Title>Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            {/* <Form.Group controlId="id">
                                <Form.Label>Id</Form.Label>
                                <Form.Control type="number" value={id} readOnly onChange={(e) => setId(e.target.value)} />
                            </Form.Group> */}
                            <Form.Group controlId='timelineName'>
                                <Form.Label>Storyline Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={timelineName}
                                    readOnly
                                    onChange={e => setTimelineName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId='modelName'>
                                <Form.Label>Instrument Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={modelName}
                                    readOnly
                                    onChange={e => setModelName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId='usersfullName'>
                                <Form.Label>User's Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={usersfullName}
                                    readOnly
                                    onChange={e => setUsersfullName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId='sessionCreated'>
                                <Form.Label>Session Date</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={sessionCreated}
                                    readOnly
                                    onChange={e => setSessionCreated(e.target.value)}
                                />
                            </Form.Group>
                            {/* <Form.Group controlId="sessionClosed">
                                <Form.Label>Session Closed</Form.Label>
                                <Form.Control type="text" value={sessionClosed} readOnly onChange={(e) => setSessionClosed(e.target.value)} />
                            </Form.Group> */}
                            <Form.Group controlId='grade'>
                                <Form.Label>Session Tracking</Form.Label>
                                <Form.Control
                                    as='select'
                                    defaultValue='Choose...'
                                    value={grade}
                                    onChange={e => setGrade(e.target.value)}
                                >
                                    <option key={"none"} value=''>Choose...</option>
                                    <option key={"cold"} value='#ff0000'>Cold</option>
                                    <option key={"warm"} value='#ffbf00'>Warm</option>
                                    <option key={"hot"} value='#00ff00'>Hot</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='note'>
                                <Form.Label>Session Notes</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    rows={4}
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                />
                            </Form.Group>
                            <Button
                                style={{ borderColor: '#A9AB2D', backgroundColor: '#A9AB2D' }}
                                block={'true'}
                                size='lg'
                                type='submit'
                            >
                                Save Changes
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Col lg='12'>
                    {searchField()}
                    <div className='mutedText'>Double-click a row to display more</div>
                    <BSTable responsive striped bordered hover variant='dark'>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <th key={header.id}>
                                                <Accordion>
                                                    <Row>
                                                        <Col
                                                            md='6'
                                                            {...header.getHeaderProps(
                                                                header.getSortByToggleProps()
                                                            )}
                                                        >
                                                            {header.render('Header')}
                                                            {header.canSort ? (
                                                                header.isSorted ? (
                                                                    header.isSortedDesc ? (
                                                                        <BiDownArrowAlt />
                                                                    ) : (
                                                                        <BiUpArrowAlt />
                                                                    )
                                                                ) : (
                                                                    <BiMinus />
                                                                )
                                                            ) : (
                                                                '\u00A0'
                                                            )}
                                                        </Col>
                                                        <Col
                                                            md='6'
                                                            style={{ float: 'right', textAlign: 'right' }}
                                                        >
                                                            {/* <Accordion.Item as={Col} eventKey='0'>
                                                                {column.canFilter ? <BiFilterAlt /> : null}
                                                            </Accordion.Item> */}
                                                            <CustomToggle eventKey='0'>
                                                                {header.canFilter ? <BiFilterAlt /> : null}
                                                            </CustomToggle>
                                                        </Col>
                                                    </Row>
                                                    <Accordion.Collapse eventKey='0'>
                                                        <div className='filter-text'>
                                                            {header.canFilter
                                                                ? header.render('Filter')
                                                                : null}
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Accordion>
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr
                                        key={row.id}
                                        onDoubleClick={handleDoubleClick}
                                        className='bodyrow'
                                        // {...row.getRowProps()}
                                    >
                                        {row.cells.map(cell => {
                                            return (
                                                <td key={cell.id}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </BSTable>
                </Col>
            </Row>
            <Row className='pagination justify-content-mg-center'>
                <Col lg='4'>
                    <Button
                        size='lg'
                        className='pageButton'
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        <Image src={leftend}></Image>
                    </Button>
                    <Button
                        size='lg'
                        className='pageButton'
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        <Image src={left}></Image>
                    </Button>
                    <Button
                        size='lg'
                        className='pageButton'
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        <Image src={right}></Image>
                    </Button>
                    <Button
                        size='lg'
                        className='pageButton'
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        <Image src={rightend}></Image>
                    </Button>
                </Col>
                <Col style={{ paddingTop: '15px' }} md={{ span: 2, offset: 2 }}>
                    Page {pageIndex + 1} of {pageOptions.length}
                </Col>
                <Col md={{ span: 2, offset: 2 }}>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label style={{ paddingTop: '7px' }}>
                                Go to page:{' '}
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type=''
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}
