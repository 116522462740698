import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import UserTable from "./userTable";

export class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        }
        this.getUsers();
    }
    getUsers = () => {
        const that = this; //some weird work around, using this.setState wasnt working, i assume this. updates the state without rerending
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }
        fetch(window.API_URL + "users/dashboard/users", requestOptions)
            .then(response => response.json())
            .then(function (result) {
                that.setState({ users: result });
            });
    }
    render() {
        return this.state.users.detail ? (<></>) : (
            <Row>
                <Col lg="12" className="h-100">
                <UserTable columns={
                    [
                        { Header: "Id", accessor: "id" },
                        { Header: "Username", accessor: "username" },
                        { Header: "First Name", accessor: "firstname" },
                        { Header: "Last Name", accessor: "lastname" },
                        { Header: "Sessions Run", accessor: "sessions" },
                        //{ Header: "Rank", accessor: "rank" }
                    ]
                } data={this.state.users} />
                </Col>
            </Row>
        )
    }
}