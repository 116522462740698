import { Button, Form, Card, Alert } from "react-bootstrap";
import React from "react";
import "./demo.scss"
import "react-widgets/scss/styles.scss";
import momentLocalizer from 'react-widgets-moment';
import { DatePicker, DropdownList } from 'react-widgets'
import moment from "moment"


export class Demo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            templates: [],
            models: [],
            remainAlive: 0,
            selectedTimeline: {},
            selectedModel: {},
            created: moment().toDate(),
            closed: moment().add(30, 'm').toDate(),
            message: "",
            messageType: "",
            authKey: "",
            name: ""
        }
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.getDropdownData();
        this.getModelDropdownData();
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    newSession = () => {
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Accept", "application/json");

        var parsedCreated = this.state.created ? this.state.created : moment();
        var parsedClosed = this.state.closed ? this.state.closed : moment(parsedCreated).add(30, 'm');

        if (parsedCreated > parsedClosed) {
            this.setState({ message: "Invalid date entry" });
            return { token: "invalid date" }
        }

        var raw = JSON.stringify({
            "template": this.state.selectedTimeline.id,
            "deathday": this.state.remainAlive,
            "created": moment(parsedCreated).format('YYYY-MM-DD HH:mm:ss'),
            "closed": moment(parsedClosed).format('YYYY-MM-DD HH:mm:ss')
        });

        var requestOptions = {
            method: "POST",
            headers: headers,
            body: raw,
            redirect: "follow"
        };
        try {
            return fetch(window.API_URL + "sessions", requestOptions);
        } catch (error) {
            return { token: "fail" }
        }

    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ message: "" });
        if (this.state.selectedTimeline.id == undefined || this.state.created == undefined || this.state.closed == undefined) {
            this.setState({ messageType: "danger" });
            var eMessage = this.state.selectedTimeline.id == undefined ? "Storyline is required" : "";
            eMessage += this.state.created == undefined ? "Session Start is required" : ""
            eMessage += this.state.closed == undefined ? "Session End is required" : ""
            this.setState({ message: eMessage });
            return;
        }
        var token = await this.newSession();
        if (token.status === 201) {
            this.setState({ message: "Session Booked" });
            this.setState({ messageType: "success" });
            this.setState({ created: moment().toDate() });
            this.setState({ closed: moment().add(30, 'm').toDate() });
            this.setState({ selectedModel: {} });
            this.setState({ selectedTimeline: {} });
            setTimeout(() => {
                this.setState({ message: "" });
            }, 3000);
        }
        else {
            if (!this.state.message) {
                this.setState({ message: "Invalid Session" });
            }
            this.setState({ messageType: "danger" });
            setTimeout(() => {
                this.setState({ message: "" });
            }, 3000);
        }
        this.props.calendarRefresh();
    }

    getDropdownData = () => {
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }
        fetch(window.API_URL + "templates", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (this._isMounted) {
                    this.setState({ templates: result });
                }
            });
    }

    getModelDropdownData = () => {
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }
        fetch(window.API_URL + "models", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (this._isMounted) {
                    this.setState({ models: result });
                }
            });
    }

    render() {
        return (
            <Card text="light" body style={{ margin: "5%", marginLeft: "20%", marginRight: "20%", backgroundColor:"#555", border: "0"}}>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="modelName">
                        <Form.Label>Instrument Name</Form.Label>
                        <DropdownList
                            dataKey="id"
                            textField="friendlyname"
                            data={this.state.models}
                            value={this.state.selectedModel.friendlyname}
                            style={{color: "white"}}
                            onChange={(e) => {
                                this.setState({ selectedModel: e });
                                this.setState({ selectedTimeline: {} });
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="timelineName">
                        <Form.Label>Storyline Name</Form.Label>
                        <DropdownList
                            dataKey="id"
                            textField="friendlyname"
                            data={this.state.templates.filter(e => e.model == this.state.selectedModel.id || this.state.selectedModel.id == undefined)}
                            value={this.state.selectedTimeline.friendlyname}
                            onChange={(e) => {
                                this.setState({ selectedTimeline: e });
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="sessionCreated">
                        <Form.Label>Session Start</Form.Label>
                        <DatePicker
                            includeTime
                            defaultValue={this.state.created}
                            value={this.state.created}
                            onChange={(e) => {
                                this.setState({ created: e });
                                if (this.state.closed.getTime() - e.getTime() < 1800000) {
                                    this.setState({ closed: new Date(e.getTime() + 1800000) });
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="sessionClosed">
                        <Form.Label>Session End</Form.Label>
                        <DatePicker
                            includeTime
                            defaultValue={this.state.closed}
                            value={this.state.closed}
                            onChange={(e) => this.setState({ closed: e })}
                        />
                    </Form.Group>
                    <br/>
                    <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" type="submit">
                        Submit
                    </Button>
                </Form>
                {
                    this.state.message &&
                    <div>
                        <br />
                        <Alert className="text-center" variant={this.state.messageType} > {this.state.message} </Alert>
                    </div>
                }
            </Card>
        )
    }
    //#region 
    /*render() {
        if (this.props.update) {
            return (
                <Row>
                    <Col>
                        <Form>
                            <Form.Group size="lg" controlId="product">
                                <Form.Label style={{ color: "#FFFFFF" }}>Storyline</Form.Label>
                                <Form.Control type="text"
                                    value={this.state.name}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="created">
                                <Form.Label style={{ color: "#FFFFFF" }}>Start Date/Time</Form.Label>
                                <Form.Control type="text"
                                    value={this.state.created}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="closed">
                                <Form.Label style={{ color: "#FFFFFF" }}>End Date/Time</Form.Label>
                                <Form.Control type="text"
                                    value={this.state.closed}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="authkey">
                                <Form.Label style={{ color: "#FFFFFF" }}>Key</Form.Label>
                                <Form.Control type="text"
                                    value={this.state.authKey}
                                    readOnly
                                />
                            </Form.Group>
                        </Form>
                        <Button
                            style={{ borderColor: "#A9AB2D", backgroundColor: "red", width: "20%" }}
                            onClick={() => { this.handleDelete(this.state.id); }}
                            block={"true"}
                            size="lg"
                        >
                            Delete
                            </Button>
                    </Col>
                </Row>
            )
        }
        else {
            return (
                <Row>
                    <Col lg={{ offset: 5, span: 2 }}>
                        <br />
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group size="lg" controlId="product">
                                <Form.Label style={{ color: "#FFFFFF" }}>Storyline</Form.Label>
                                <Form.Control as="select"
                                    required
                                    defaultValue="Choose..."
                                    onChange={(e) => this.setState({ templateId: e.target.value })}>
                                    <option value="0">Choose...</option>
                                    {this.state.templates.map(e => <option value={e.id}>{e.name}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group size="lg" controlId="created" className="created">
                                <Form.Label style={{ color: "#FFFFFF" }}>Start Date/Time</Form.Label>
                                <DateTimePicker
                                    value={new Date(this.state.created)}
                                    onChange={e => this.setState({ created: e })}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="closed" className="closed">
                                <Form.Label style={{ color: "#FFFFFF" }}>End Date/Time</Form.Label>
                                <DateTimePicker
                                    value={new Date(this.state.closed)}
                                    onChange={e => this.setState({ closed: e })}
                                />
                            </Form.Group>
                            <br />
                            <Row className="justify-content-md-center">
                                <Col >
                                    <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} type="submit" block={"true"} size="lg">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                            {
                                this.state.message &&
                                <div>
                                    <br />
                                    <Alert className="text-center" variant={this.state.messageType} > {this.state.message} </Alert>
                                </div>
                            }
                        </Form>
                    </Col>
                </Row>)
        }
    }*/
    //#endregion
}