import React from 'react'
import { Badge, Container } from 'react-bootstrap';
import SessionTable from "./sessionTable";

export class MySessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: []
        }
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.getSessions();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getSessions = () => {
        const that = this;
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }
        fetch(window.API_URL + "sessions/dashboard/me", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (this._isMounted) {
                    that.setState({ sessions: result });
                }
            });

    }
    render() {
        return this.state.sessions.detail ? (<></>) : (
            <Container fluid style={{ backgroundColor: "#555555" }}>
                <SessionTable hideSearch={true} columns={
                    [
                        { Header: "Id", accessor: "id" },
                        { Header: "Storyline", accessor: "timelinename", width: 150 },
                        { Header: "Instrument", accessor: "modelname" },
                        { Header: "Session Date", accessor: "sessioncreated" },
                        { Header: "Closed", accessor: "sessionclosed" },
                        { Header: "Tracking", accessor: "gradeBadge", disableFilters: true, Cell: ({ row: { original } }) => <Badge style={{ backgroundColor: original.grade, display: 'block', height: "20px" }}></Badge> }
                    ]
                } data={this.state.sessions}
                    reloadHandle={this.getSessions} />
            </Container>
        )
    }
}