import React from 'react'
import { Badge, Container } from 'react-bootstrap';
import SessionTable from "./sessionTable";

export class Sessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: []
        }
        this.getSessions();
    }
    getSessions = () => {
        const that = this;
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }
        fetch(window.API_URL + "sessions/dashboard", requestOptions)
            .then(response => response.json())
            .then(function (result) {
                that.setState({ sessions: result });
            });

    }
    render() {
        return this.state.sessions.detail ? (<></>) : (
            <Container fluid style={{ backgroundColor: "#555555" }}>
                <SessionTable columns={
                    [
                        { Header: "Id", accessor: "id" },
                        { Header: "Storyline", accessor: "timelinename", width: 200 },
                        { Header: "Instrument", accessor: "modelname", width: 200 },
                        { Header: "User", accessor: "usersfullname", width: 200 },
                        { Header: "Session Date", accessor: "sessioncreated", width: 200 },
                        { Header: "Closed", accessor: "sessionclosed", width: 200 },
                        { Header: "Tracking", accessor: "gradeBadge", width: 200, disableFilters: true, disableSortBy: true, Cell: ({ row: { original } }) => <span style={{ backgroundColor: original.grade, display: 'block', height: "20px" }}></span> }
                    ]
                }
                    data={
                        this.state.sessions.filter(e => (e.usersfullname != null) && (e.usersfullname !== ''))
                    }
                    reloadHandle={this.getSessions} />
            </Container>
        )
    }
}