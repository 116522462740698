import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Card, Col, Row, Modal, Form, Button } from "react-bootstrap";
import { DateTimePicker } from 'react-widgets'
import { BarChart } from "./barChart.js";
import "./dashboard.css";
import { HiFilter } from 'react-icons/hi'
import { PieChart } from "./pieChart.js";
import moment from "moment"
import {ErrorBoundary} from "./errorBoundry.js"

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rank: 0,
      sppData: {},
      spuData: {},
      sprData: {},
      saData: {},
      suppData: {},
      sowData: {},
      modelList: [],
      templateList: [],
      userList: [],
      exportStartDate: null,
      exportEndDate: null,
      exportModelId: null,
      exportTemplateId: null,
      exportUserId: null,
      dppStartDate: null,
      dppEndDate: null,
      dpuStartDate: null,
      dpuEndDate: null,
      dppShowFilters: false,
      dpuShowFilters: false,
      showSessionsPerModelModal: false,
      showSessionsPerTemplateModal: false,
      showUserSessionsModal: false
    }
    this.getBarData("analytics/sessionsperproduct", "sppData", "templatename", "sessioncount", "Product");
    this.getBarData("analytics/sessionsperuser", "spuData", "friendlyname", "sessioncount", "User");
    this.getStackedBarData("analytics/storylineusersperproduct", "suppData", "productname", "usercount", "label");
    this.getPieData("analytics/sessionsactivity", "saData", "sessioncount", "User");
    this.getRegionPieData("analytics/sessionsperregion", "sprData");
    this.getBarData("analytics/sessionshostedoverweek", "sowData", "friendlyname", "sessioncount", "User");
    this.getRank();
    this.getModelList();
    this.getTemplateList();
    this.getUserList();
  }

  getUserList = () => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + "users/list", requestOptions)
      .then(response => response.json())
      .then(result => this.setState({ userList: result }))
  }

  getModelList = () => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + "models/list", requestOptions)
      .then(response => response.json())
      .then(result => this.setState({ modelList: result }))
  }

  getTemplateList = () => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + "templates/list", requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({ templateList: result });
      })
  }

  handleSessionsPerModelClose = () => {
    this.setState({ showSessionsPerModelModal: false });
    this.handleExportStateReset();
  }
  handleSessionsPerModelShow = () => {
    this.setState({ showSessionsPerModelModal: true });
  }
  handleSessionsPerModelSubmit = () => {
    this.getExportData(
      `sessionspermodel?`
        .concat(this.state.exportStartDate != null ? `start_date=${moment(this.state.exportStartDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
        .concat(this.state.exportEndDate != null ? `&end_date=${moment(this.state.exportEndDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
        .concat(this.state.exportModelId != null ? `&model=${this.state.exportModelId}` : ``).replace("?&", "?"),
      moment(moment.now()).format('YYYY-MM-DD HH:mm:ss') + " SpM",
      () => this.handleSessionsPerModelClose()
    )
  }
  handleSessionsPerTemplateClose = () => {
    this.setState({ showSessionsPerTemplateModal: false });
    this.handleExportStateReset();
  }
  handleSessionsPerTemplateShow = () => {
    this.setState({ showSessionsPerTemplateModal: true });
  }
  handleSessionsPerTemplateSubmit = () => {
    this.getExportData(
      `sessionspertemplate?`
        .concat(this.state.exportStartDate != null ? `start_date=${moment(this.state.exportStartDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
        .concat(this.state.exportEndDate != null ? `&end_date=${moment(this.state.exportEndDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
        .concat(this.state.exportModelId != null ? `&model=${this.state.exportModelId}` : ``)
        .concat(this.state.exportTemplateId != null ? `&template=${this.state.exportTemplateId}` : ``).replace("?&", "?"),
      moment(moment.now()).format('YYYY-MM-DD HH:mm:ss') + " SUpP",
      () => this.handleSessionsPerTemplateClose()
    )
  }
  handleUserSessionsClose = () => {
    this.setState({ showUserSessionsModal: false });
    this.handleExportStateReset();
  }
  handleUserSessionsShow = () => {
    this.setState({ showUserSessionsModal: true });
  }
  handleUserSessionsSubmit = () => {
    this.getExportData(
      `usersessions?`
        .concat(this.state.exportStartDate != null ? `start_date=${moment(this.state.exportStartDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
        .concat(this.state.exportEndDate != null ? `&end_date=${moment(this.state.exportEndDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
        .concat(this.state.exportUserId != null ? `&user=${this.state.exportUserId}` : ``).replace("?&", "?"),
      moment(moment.now()).format('YYYY-MM-DD HH:mm:ss') + " SpM",
      () => this.handleUserSessionsClose()
    )
  }

  handleExportStateReset = () => {
    this.setState({ exportStartDate: null });
    this.setState({ exportEndDate: null });
    this.setState({ exportModelId: null });
    this.setState({ exportTemplateId: null });
    this.setState({ exportUserId: null });
  }

  getRank = () => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + "users/me?source=dashboard", requestOptions)
      .then(response => response.json())
      .then(result => this.setState({ rank: result.rank }))

  };

  getBarData = (requestUrl, dataState, xCol, yCol, chartLabel) => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    try {
      fetch(window.API_URL + requestUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.setState({
          [dataState]: {
            labels: result[0] ? result.map(e => e[xCol]) : "",
            datasets: [{
              label: chartLabel,
              data: result[0] ? result.map(f => f[yCol]) : "",
              backgroundColor: "#A9AB2D",
              fillColor: "#A9AB2D",
              color: "#FFFFFF"
            }]
          }
        })
      });
    } catch (error) {
      this.setState({
        [dataState]: {
          datasets: [{
            backgroundColor: "#FFFFFF",
            fillColor: "#FFFFFF"
          }]
        }
      });
    }
  }

  transformStackedBarData = (a, xCol, yCol, seriesName) => {
    let labels = [...new Set(a.map(f => f[xCol]))];
    let uniquelabel = [...new Set(a.map(f => f[seriesName]))];
    var dataSets = [];

    for (let j = 0; j < uniquelabel.length; j++) {
      var color = this.getStackedBarColours(j);
      var newItem = {
        label: uniquelabel[j],
        backgroundColor: color,
        fillColor: color,
        data: new Array(labels.length).fill(0)
      }
      dataSets.push(newItem)
    }

    //var colourcounter = 0;
    for (let k = 0; k < a.length; k++) {
      var labelIndex = dataSets.findIndex(element => element.label === a[k][seriesName]);
      var labelsIndex = labels.findIndex(element => element === a[k][xCol]);
      dataSets[labelIndex].data[labelsIndex] += a[k][yCol];
      //colourcounter++;
    }

    var returnArr = {
      labels: labels,
      datasets: dataSets
    };
    return returnArr;
  }

  getStackedBarColours = (index) => {
    var colourArr = ["#A9AB2D", "#E39B5F", "#F95CFA", "#5992F0"];
    return colourArr[index % 4];
  }

  getStackedBarData = (requestUrl, dataState, xCol, yCol, seriesName) => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    try {
      fetch(window.API_URL + requestUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.setState({
            [dataState]: this.transformStackedBarData(result, xCol, yCol, seriesName)
          });
        });
    } catch (error) {
      this.setState({
        [dataState]: {
          datasets: [{
            backgroundColor: "#FFFFFF",
            fillColor: "#FFFFFF"
          }]
        }
      });
    }
  }

  parsePieData = (data) => {
    var returnData = [];
    Object.keys(data).forEach(item => {
      returnData.push(data[item]);
    });
    return returnData;
  }

  PieChartActivity = () => {
    if (((this.state.saData?.datasets?.length ?? 0) > 0)) {
      if (this.state.saData.datasets[0].data !== "" && this.state.saData.datasets[0].data.reduce((a, b) => a + b, 0) > 0) {
        return (
          <PieChart data={this.state.saData} />
        )
      }
    }
    return (
      <h3 style={{ textAlign: "center" }}>NO DATA</h3>
    )
  }

  getPieData = (requestUrl, dataState, dataSet, chartLabel) => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + requestUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
        [dataState]: {
          datasets: [{
            label: chartLabel,
            data: result[0] ? this.parsePieData(result[0]) : "",
            backgroundColor: ["#A9AB2D", "#A0A0A0", "#222222", "#FFFFFF"],
            fillColor: ["#A9AB2D", "#A0A0A0", "#222222", "#FFFFFF"]
          }],
          labels: ["Active Sessions", "Inactive Sessions"]
        }
      })
    });
  }


  regionPieChartActivity = () => {
    if (((this.state.sprData?.datasets?.length ?? 0) > 0)) {
      if (this.state.sprData.datasets[0].data !== "" && (this.state.sprData?.datasets[0]?.data.reduce((a, b) => a + b, 0) ?? 0) > 0) {
        return (
          <PieChart data={this.state.sprData} />
        )
      }
    }
    return (
      <h3 style={{ textAlign: "center" }}>NO DATA</h3>
    )
  }

  parseRegionPieData = (data, index) => {
    var returnData = [];
    data.forEach((item) => {
      returnData.push(item[index])
    });
    return returnData;
  }

  getRegionPieData = (requestUrl, dataState) => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + requestUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          [dataState]: {
            datasets: [{
              label: "Region",
              data: result[0] ? this.parseRegionPieData(result, "regioncount") : "",
              backgroundColor: ["#A9AB2D", "#A0A0A0", "#222222", "#FFFFFF"],
              fillColor: ["#A9AB2D", "#A0A0A0", "#222222", "#FFFFFF"]
            }],
            labels: result[0] ? this.parseRegionPieData(result, "regionname") : ""
          }
        })
      });
  }

  getExportData = (requestUrl, fileName, callback) => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + "export/" + requestUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link);
        link.click();
        callback();
      });
  }

  populateUserList = () => {
    if (this.state.rank >= 5) {
      return this.state.userList.map((e, index) => <option key={"userlist" + index} value={e.id}>{e.name}</option>)
    }
  }

  populateUserDropdownDefault = () => {
    if (this.state.rank >= 5) {
      return "All";
    }
    else {
      return "Current User";
    }
  }

  render() {
    return (
      <Row style={{ backgroundColor: "#555555" }} >
        <Modal show={this.state.showSessionsPerModelModal} onHide={this.handleSessionsPerModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Sessions Per Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="startDate">
                <Form.Label>From Date</Form.Label>
                <DateTimePicker
                  value={this.state.exportStartDate == null ? null : new Date(this.state.exportStartDate)}
                  onChange={e => this.setState({ exportStartDate: e })}
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>To Date</Form.Label>
                <DateTimePicker
                  value={this.state.exportEndDate == null ? null : new Date(this.state.exportEndDate)}
                  onChange={e => this.setState({ exportEndDate: e })}
                />
              </Form.Group>
              <Form.Group controlId="modelId">
                <Form.Label>Product Name</Form.Label>
                <Form.Control as="select"
                  defaultValue="All"
                  value={this.state.exportModelId}
                  onChange={(e) => this.setState({ exportModelId: e.target.value })}>
                  <option key={"all"} value={null} >All</option>
                  {this.state.modelList.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                </Form.Control>
              </Form.Group>
              <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" onClick={this.handleSessionsPerModelSubmit}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showSessionsPerTemplateModal} onHide={this.handleSessionsPerTemplateClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Sessions Per Storyline
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="startDate">
                <Form.Label>From Date</Form.Label>
                <DateTimePicker
                  value={this.state.exportStartDate == null ? null : new Date(this.state.exportStartDate)}
                  onChange={e => this.setState({ exportStartDate: e })}
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>To Date</Form.Label>
                <DateTimePicker
                  value={this.state.exportEndDate == null ? null : new Date(this.state.exportEndDate)}
                  onChange={e => this.setState({ exportEndDate: e })}
                />
              </Form.Group>
              <Form.Group controlId="timelineId">
                <Form.Label>Storyline Name</Form.Label>
                <Form.Control as="select"
                  defaultValue="All"
                  value={this.state.exportTemplateId}
                  onChange={(e) => this.setState({ exportTemplateId: e.target.value })}>
                  <option key={null} value={null} >All</option>
                  {this.state.templateList.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="modelId">
                <Form.Label>Product Name</Form.Label>
                <Form.Control as="select"
                  defaultValue="All"
                  value={this.state.exportModelId}
                  onChange={(e) => this.setState({ exportModelId: e.target.value })}>
                  <option key={null} value={null} >All</option>
                  {this.state.modelList.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                </Form.Control>
              </Form.Group>
              <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" onClick={this.handleSessionsPerTemplateSubmit}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showUserSessionsModal} onHide={this.handleUserSessionsClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              User Sessions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="startDate">
                <Form.Label>From Date</Form.Label>
                <DateTimePicker
                  value={this.state.exportStartDate == null ? null : new Date(this.state.exportStartDate)}
                  onChange={e => this.setState({ exportStartDate: e })}
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>To Date</Form.Label>
                <DateTimePicker
                  value={this.state.exportEndDate == null ? null : new Date(this.state.exportEndDate)}
                  onChange={e => this.setState({ exportEndDate: e })}
                />
              </Form.Group>
              <Form.Group controlId="userId">
                <Form.Label>User</Form.Label>
                <Form.Control as="select"
                  defaultValue={this.populateUserDropdownDefault()}
                  value={this.state.exportUserId}
                  onChange={(e) => this.setState({ exportUserId: e.target.value })}>
                  <option key={"userDrop"} value={null} >{this.populateUserDropdownDefault()}</option>
                  {
                    this.populateUserList()
                  }
                </Form.Control>
              </Form.Group>
              <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" onClick={this.handleUserSessionsSubmit}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Col xs="6" style={{ paddingTop: "15px", backgroundColor: "#555555" }}>
          <Card>
            <Card.Header style={{ backgroundColor: "#222222", color: "#FFFFFF", textAlign: "center" }}>
              Demos Per Product
            </Card.Header>
            <Card.Body>
              <ErrorBoundary>
                <BarChart stacked={false} data={this.state.sppData} />
              </ErrorBoundary>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="11">
                  <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} onClick={() => this.handleSessionsPerModelShow()}>Export</Button>
                </Col>
                <Col xs="1">
                  <HiFilter style={{}} onClick={e => this.setState({ dppShowFilters: !this.state.dppShowFilters })} />
                </Col>
              </Row>
              <Row hidden={!this.state.dppShowFilters}>
                <Col xs="2" style={{ alignSelf: "center" }}>
                  <Card.Text style={{ textAlign: "end" }} >Date From</Card.Text>
                </Col>
                <Col xs="4">
                  <DateTimePicker
                    value={this.state.dppStartDate == null ? null : new Date(this.state.dppStartDate)}
                    onChange={(e) => {
                      this.setState({ dppStartDate: e });
                      //bad hack
                      var url = `sessionsperproduct?`
                        .concat(e != null ? `start_date=${moment(e).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .concat(this.state.dppEndDate != null ? `&end_date=${moment(this.state.dppEndDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .replace("?&", "?")

                      this.getBarData("analytics/" + url, "sppData", "templatename", "sessioncount", "Product");
                    }}
                  />
                </Col>
                <Col xs="2" style={{ alignSelf: "center" }} >
                  <Card.Text style={{ textAlign: "end" }} >Date To</Card.Text>
                </Col>
                <Col xs="4">
                  <DateTimePicker
                    value={this.state.dppEndDate == null ? null : new Date(this.state.dppEndDate)}
                    onChange={(e) => {
                      this.setState({ dppEndDate: e });
                      //bad hack
                      var url = `sessionsperproduct?`
                        .concat(this.state.dppStartDate != null ? `start_date=${moment(this.state.dppStartDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .concat(e != null ? `&end_date=${moment(e).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .replace("?&", "?")
                      this.getBarData("analytics/" + url, "sppData", "templatename", "sessioncount", "Product");
                    }}
                  />
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingTop: "15px", backgroundColor: "#555555" }}>
          <Card>
            <Card.Header style={{ backgroundColor: "#222222", color: "#FFFFFF", textAlign: "center" }}>
              Demos Per User
            </Card.Header>
            <Card.Body>
              <ErrorBoundary>
                <BarChart indexAxis={"y"} data={this.state.spuData} />
              </ErrorBoundary>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs="11">
                  <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} onClick={() => this.handleUserSessionsShow()}>Export</Button>
                </Col>
                <Col xs="1">
                  <HiFilter style={{}} onClick={e => this.setState({ dpuShowFilters: !this.state.dpuShowFilters })} />
                </Col>
              </Row>
              <Row hidden={!this.state.dpuShowFilters}>
                <Col xs="2" style={{ alignSelf: "center" }}>
                  <Card.Text style={{ textAlign: "end" }} >Date From</Card.Text>
                </Col>
                <Col xs="4">
                  <DateTimePicker
                    value={this.state.dpuStartDate == null ? null : new Date(this.state.dpuStartDate)}
                    onChange={(e) => {
                      this.setState({ dpuStartDate: e });
                      //bad hack
                      var url = `sessionsperuser?`
                        .concat(e != null ? `start_date=${moment(e).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .concat(this.state.dpuEndDate != null ? `&end_date=${moment(this.state.dpuEndDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .replace("?&", "?")
                      this.getBarData("analytics/" + url, "spuData", "friendlyname", "sessioncount", "User");
                    }}
                  />
                </Col>
                <Col xs="2" style={{ alignSelf: "center" }}>
                  <Card.Text style={{ textAlign: "end" }} >Date To</Card.Text>
                </Col>
                <Col xs="4">
                  <DateTimePicker
                    value={this.state.dpuEndDate == null ? null : new Date(this.state.dpuEndDate)}
                    onChange={(e) => {
                      this.setState({ dpuEndDate: e });
                      //bad hack
                      var url = `sessionsperuser?`
                        .concat(this.state.dpuStartDate != null ? `start_date=${moment(this.state.dpuStartDate).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .concat(e != null ? `&end_date=${moment(e).format("YYYY-MM-DD HH:mm:ss")}` : ``)
                        .replace("?&", "?")
                      this.getBarData("analytics/" + url, "spuData", "friendlyname", "sessioncount", "User");
                    }}
                  />
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingTop: "15px", backgroundColor: "#555555" }}>
          <Card>
            <Card.Header style={{ backgroundColor: "#222222", color: "#FFFFFF", textAlign: "center" }}>
              Storyline Users Per Product
            </Card.Header>
            <Card.Body>
              <ErrorBoundary>
                <BarChart stacked={true} data={this.state.suppData} />
              </ErrorBoundary>
            </Card.Body>
            <Card.Footer>
              <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} onClick={() => this.handleSessionsPerTemplateShow()}>Export</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingTop: "15px", backgroundColor: "#555555" }}>
          <Card>
            <Card.Header style={{ backgroundColor: "#222222", color: "#FFFFFF", textAlign: "center" }}>
              Demo Activity Today
            </Card.Header>
            <Card.Body>
              <ErrorBoundary>
                {this.PieChartActivity()}
              </ErrorBoundary>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingTop: "15px", backgroundColor: "#555555" }}>
          <Card>
            <Card.Header style={{ backgroundColor: "#222222", color: "#FFFFFF", textAlign: "center" }}>
              Demos Hosted Over Past Week
            </Card.Header>
            <Card.Body>
              <ErrorBoundary>
                <BarChart indexAxis={"y"} data={this.state.sowData} />
              </ErrorBoundary>
            </Card.Body>
          </Card>
          <br />
        </Col>
        <Col xs="6" style={{ paddingTop: "15px", backgroundColor: "#555555" }}>
          <Card>
            <Card.Header style={{ backgroundColor: "#222222", color: "#FFFFFF", textAlign: "center" }}>
              Regions
            </Card.Header>
            <Card.Body>
              <ErrorBoundary>
                {this.regionPieChartActivity()}
              </ErrorBoundary>
            </Card.Body>
          </Card>
          <br />
        </Col>
      </Row>
    );
  }
}