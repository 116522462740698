import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Calendar as BigCalendar, Views as CalendarViews, momentLocalizer } from 'react-big-calendar'
import { RiFileCopyFill } from 'react-icons/ri'
import { Col, Form, Row, Modal, Button, Overlay, Tooltip, Alert } from "react-bootstrap";
import moment from 'moment'
import 'moment-timezone';

import "./calendar.scss"

const localizer = momentLocalizer(moment);

export function Calendar(props) {
    const [calendarData, setCalendarData] = useState([]);
    const [selectedSession, setSelectedSession] = useState({});
    const [emailList, setEmailList] = useState("");
    const [showInvite, setShowInvite] = useState(false);
    const target = useRef(null);
    const [show, setShow] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const [mounted, setMounted] = useState(false);
    const getData = useCallback(() => {
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }
        fetch(window.API_URL + "sessions/calendar", requestOptions)
            .then(response => response.json())
            .then(result => {
                setCalendarData(parseData(result));
            });
    },[]);

    const parseData = (data) => {
        var returnArr = [];
        data.forEach(item => {
            returnArr.push({
                id: item.id,
                title: item.timelinename + " " + item.modelname,
                start: new Date(item.sessioncreated),
                end: new Date(item.sessionclosed),
                allDay: item.sessioncreated === "" && item.sessionclosed === "",
                authKey: item.authkey
            });
        });
        return returnArr;
    }

    const refreshCal = useCallback(() => {
        setShow(false);
        getData();
        setSelectedSession({});
    },[getData])

    useEffect(() => {
        if (!mounted) {
            getData();
            props.refreshCallback(refreshCal);
        }
        setMounted(true);
    }, [getData, mounted, props, refreshCal]);

    const onHide = () => {
        setShow(false);
        setSelectedSession({});
        setEmailList("");
        setShowInvite(false);
    }

    const onHideInvite = () => {
        setShowInvite(false);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");

        var raw = JSON.stringify({
            "session": selectedSession.id,
            "recipients": emailList.replace(/\n|\r|\r\n|\t| |,/g, ';').split(';').filter(e => e !== "")

        });

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        }

        fetch(window.API_URL + "sessions/invite", requestOptions)
            .then(response => response.json())
            .then(result => {
                setShowInvite(false);
            });
    }

    const demoModal = () => {
        if (show) {
            return (
                <Modal
                    show={show}
                    onHide={onHide}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Session
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="sessionName">
                            <Form.Label>Session Name</Form.Label>
                            <Form.Control type="text" value={selectedSession.title} readOnly />
                        </Form.Group>
                        <Form.Group controlId="sessionCreated">
                            <Form.Label>Session Start</Form.Label>
                            <Form.Control type="text" value={moment(selectedSession.start.getTime()).format('YYYY-MM-DD HH:mm:ss')} readOnly />
                        </Form.Group>
                        <Form.Group controlId="sessionClosed">
                            <Form.Label>Session End</Form.Label>
                            <Form.Control type="text" value={moment(selectedSession.end.getTime()).format('YYYY-MM-DD HH:mm:ss')} readOnly />
                        </Form.Group>
                        <Form.Group controlId="authkey">
                            <Form.Label>Session URL</Form.Label>
                            <>
                                <RiFileCopyFill
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${window.APP_URL}?authkey=${selectedSession.authKey}`);
                                        setShowCopy(true);
                                        setTimeout(() => {
                                            setShowCopy(false);
                                        }, 3000);
                                    }}
                                    style={{float:"right"}}
                                />
                                 <Alert
                                    show={showCopy}
                                    style={{display:"inline", float: "right"}}
                                    variant='success'
                                >
                                    Copied
                                </Alert>
                            </>
                            <Form.Control type="text" value={`${window.APP_URL}?authkey=${selectedSession.authKey}`} readOnly />
                        </Form.Group>
                        <Button
                            ref={target}
                            style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }}
                            block={"true"}
                            size="lg"
                            onClick={() => { window.open(`${window.APP_URL}?authkey=${selectedSession.authKey}`) }}>
                            Launch Session
                        </Button>
                        <Button
                            style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }}
                            block={"true"}
                            size="lg"
                            onClick={() => { setShowInvite(true) }}>
                            Email Invitees
                        </Button>
                        {
                            showInvite &&
                            <Form onSubmit={handleSubmit}>
                                <br />
                                <Form.Group controlId="note">
                                    <Form.Label>Email List</Form.Label>
                                    <Form.Control as="textarea" rows={4} value={emailList} onChange={(e) => setEmailList(e.target.value)} />
                                </Form.Group>
                                <Button
                                    style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }}
                                    size="lg"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Form>
                        }
                    </Modal.Body>
                </Modal>
            )
        }
    }

    return (
        <Row className="justify-content-mg-center">
            {demoModal()}
            <Col lg="1">
            </Col>
            <Col lg="10">
                <br />
                <BigCalendar
                    events={calendarData}
                    onDoubleClickEvent={(e) => { setShow(true); setSelectedSession(e); }}
                    defaultView={CalendarViews.DAY}
                    views={['day', 'work_week']}
                    step={15}
                    showMultiDayTimes
                    min={
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate(),
                            9
                        )
                    }
                    max={
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate(),
                            17
                        )
                    }
                    defaultDate={new Date()}
                    localizer={localizer}
                />
                <br />
                <Button style={{ backgroundColor: "unset", border: "0" }} onClick={() => refreshCal()}>
                    Refresh
                </Button>
            </Col>
        </Row>
    )
}