//https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications - cleaner way than I was doing
import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem("access_token");
    const userToken = JSON.parse(tokenString);
    return userToken?.access_token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (!document.cookie.length) {
      document.cookie = `access_token=${JSON.stringify(userToken)}; Secure; SameSite=Lax; Max-Age=28800; domain=envoke-demos.com`
    }
    
    sessionStorage.setItem("access_token", JSON.stringify(userToken));
    setToken(userToken.access_token);
  };

  return {
    setToken: saveToken,
    token
  }
}