import React, { useState } from "react"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Button from "react-bootstrap/Button"
import { Alert, Form } from "react-bootstrap";

export default function HelpPopover (props) {

    const [text, setText] = useState("");
    const [showSent, setShowSent] = useState(false);

    const handleHelpSubmit = (e) => {
        e.preventDefault();

        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");

        var raw = JSON.stringify({ "message": text});

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        
        fetch(window.API_URL + "dashboard/support", requestOptions)
            .then()
            .catch(error => console.log('error', error));
        setShowSent(true);
        setText("");
        setTimeout(() => {
            setShowSent(false);
        }, 5000);
    }


    const popover = (props) => (
        <Popover {...props} id="popover-basic">
            <Popover.Header as="h3" style={{fontWeight:"bold", backgroundColor: "#555555", color: "#FFFFFF"}}>Send us a support message</Popover.Header>
            <Popover.Body style={{backgroundColor: "#555555"}}>
                <Form size="lg" onSubmit={handleHelpSubmit}>
                    <Form.Group size="lg" controlId="Message">
                        <Form.Control 
                            as="textarea"
                            placeholder="Enter Message..."
                            rows={12}
                            style={{  color: "#FFFFFF", backgroundColor: "#A0A0A0", borderColor: "#A0A0A0" }}
                            required
                            autoFocus
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </Form.Group>
                    {
                        showSent&&<Alert className="text-center" variant="success" >Thank you. <br/>Our support team will get<br/>back to you shortly.</Alert>
                    }
                    
                    <Button style={{ boxShadow: "box-shadow: 0px 0px 0px 0.2rem  rgba(169,171,45,0.7);", borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" type="submit">
                        Submit
                    </Button>
                </Form>
            </Popover.Body>
        </Popover>
    );
    
    
    return (
        <OverlayTrigger {...props} trigger="click" placement="top" overlay={popover}>
            <Button style={{ position: "fixed", bottom: "0px", left: "95%", borderColor: "#A9AB2D", backgroundColor: "#A9AB2D", marginBottom: "10px", fontWeight: "bold" }} variant="success">Help</Button>
        </OverlayTrigger>
    )
}


