import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Alert, Button, Container, Row, Col } from "react-bootstrap"
import "./login.css";

async function loginUser(params) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  var urlencoded = new URLSearchParams();
  urlencoded.append("username", params.username);
  urlencoded.append("password", params.password);
  urlencoded.append("grant_type", "");
  urlencoded.append("scope", "");
  urlencoded.append("client_id", "");
  urlencoded.append("client_secret", "");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow"
  };

  return fetch(window.API_URL + "login", requestOptions);
}

export default function Login({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function validateForm() {
    return username.length && password.length;
  }
  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    var tokenJson = await token.json();
    if (token.status === 200) {
      setToken(tokenJson);
    }
    else {
      sessionStorage.removeItem("access_token");
      setErrorMessage("Invalid Login");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }

  return (
    <Container className="Login">
      <Form size="lg" onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label style={{ color: "#FFFFFF" }}>Username</Form.Label>
          <Form.Control
            placeholder="Enter Username..."
            style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
            required
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group size="lg" controlId="password">
          <Form.Label style={{ color: "#FFFFFF" }}>Password</Form.Label>
          <Form.Control
            placeholder="Enter Password..."
            style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
            required
            autoComplete="current-password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <br />
        <Row className="justify-content-md-center">
          <Col xs={12} sm={4} md={8} style={{textAlign:"center"}}>
            <Button style={{ borderColor: "#A9AB2D", backgroundColor:"#A9AB2D", minWidth:"200px"}} block={"true"} size="lg" type="submit" disabled={!(username.length && password.length)}>
              Login
            </Button>
          </Col>
        </Row>
        {
          errorMessage &&
          <div>
            <br />
            <Alert className="text-center" variant="danger" > {errorMessage} </Alert>
          </div>
        }
      </Form>
    </Container>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}