//set HTTPS=true&&npm start
import React, { useState } from "react";
import Login from "./containers/login"
import { Users } from "./containers/users"
import { Sessions } from "./containers/sessions"
import { MySessions } from "./containers/mySessions"
import ResetPassword from "./containers/resetPassword"
import PasswordReset from "./containers/passwordReset"
import ChangePassword from "./containers/changePassword"
import { Calendar } from "./containers/calendar"
import { Demo } from "./containers/demo"
import { Dashboard } from "./containers/dashboard";
import useToken from "./containers/useToken";
import logo from "./content/envokelogoinverted.png"
import { Image, Tabs, Tab, Row, Container, Col, Button, Popover, OverlayTrigger } from "react-bootstrap";
import "./App.css";
import HelpPopover from "./containers/helpPopover";

function App() {
  const { token, setToken } = useToken();
  const [rank, setRank] = useState();
  //#region spooky magic ~*~*~*~*~ pass a function from child to parent to a different child
  const [calCallback, setCalCallback] = useState(() => { });
  const setCalendarUpdateCallback = (e) => {
    setCalCallback(() => e);
  }
  const calendarUpdateCallback = () => {
    calCallback();
  };
  //#endregion of spooky magic ~*~*~*~*~

  const getRank = () => {
    var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append("Accept", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }
    fetch(window.API_URL + "users/me", requestOptions)
      .then(response => response.json())
      .then(result => setRank(result.rank))
      .catch(()=>{
        console.log("Unable to log in!");
        sessionStorage.removeItem("access_token");
      });

  };

  function handleLogoutClick() {
    sessionStorage.removeItem("access_token");
    window.location.reload();
  }

  var query = window.location.search.substr(1);
  const params = new URLSearchParams(query);
  if (params.get("passwordreset") === "true") {
    return (
      <Container style={{ backgroundColor: "#555555", height: "100vh" }} fluid className="wrapper">
        <Row className="justify-content-md-center">
          <Col>
            <PasswordReset resetToken={params.get("token")} />
          </Col>
        </Row>
      </Container>
    )
  }
  if (!token) {
    return (
      <Container style={{ backgroundColor: "#555555", height: "100vh" }} fluid className="wrapper">
        <Row className="justify-content-md-center">
          <Col xs={10} sm={2} md={4}>
            <Image src={logo} fluid />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Login setToken={setToken} />
        </Row>
        <Row className="justify-content-md-center">
          <ResetPassword />
        </Row>
      </Container>
    )
  }
  if (token && !rank) {
    getRank();
  }
  if (rank >= 5) {
    return (
      <>
        <Container style={{ backgroundColor: "#555555", padding: "0" }} fluid className="wrapper d-flex flex-column">
          <Row style={{ borderBottom: "0", backgroundColor: "#36323D" }} className="justify-content-md-center">
            <Col md={{ span: 2 }} style={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D", marginBottom: "10px" }}
                onClick={() => { window.open(`https://app.envoke-demos.com/`) }}>
                <b>Launch Envoke</b>
              </Button>
            </Col>
            <Col md={{ span: 2, offset: 3 }}>
              <Image rounded src={logo} fluid />
            </Col>
            <Col style={{ display: "flex", alignItems: "flex-end" }} md={{ span: 1, offset: 4 }}>
              <Button onClick={handleLogoutClick} style={{ float: "right", backgroundColor: "transparent", border: "0", marginBottom: "10px" }} className="pull-right">
                Logout
              </Button>
            </Col>
          </Row>
        </Container>
        <Container style={{ backgroundColor: "#555555", padding: "0" }} fluid className="wrapper">
          <Row className="content-row justify-content-mg-center">
            <Col lg="12" style={{ paddingLeft: "0", paddingRight: "0" }}>
              <Tabs style={{ backgroundColor: "#222222", color:"#ffffff" }} defaultActiveKey="dashboard">
                <Tab eventKey="dashboard" title="Dashboard">
                  <Dashboard />
                </Tab>
                <Tab style={{ color: "#ffffff" }} eventKey="sessions" title="Sessions">
                  <Sessions />
                </Tab>
                <Tab style={{ color: "#ffffff" }} eventKey="users" title="Users">
                  <Users />
                </Tab>
                <Tab tabClassName="shoveTabLeft" style={{ color: "#ffffff" }} eventKey="schedule" title="Schedule">
                  <Calendar refreshCallback={setCalendarUpdateCallback} />
                </Tab>
                <Tab tabClassName="bookdemo-tab" style={{ color: "#ffffff" }} eventKey="bookdemo" title="Book Demo">
                  <Demo calendarRefresh={calendarUpdateCallback} />
                </Tab>
                <Tab tabClassName="changepassword-tab" style={{ color: "#ffffff" }} eventKey="changepassword" title="Change Password">
                  <ChangePassword />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
        <HelpPopover></HelpPopover>

      </>
    );
  }
  else {
    return (
      <>
        <Container style={{ backgroundColor: "#555555", padding: "0" }} fluid className="wrapper d-flex flex-column">
          <Row style={{ borderBottom: "0", backgroundColor: "#36323D" }} className="justify-content-md-center">
            <Col md={{ span: 2 }} style={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D", marginBottom: "10px" }}
                onClick={() => { window.open(`https://app.envoke-demos.com/`) }}>
                <b>Launch Envoke</b>
              </Button>
            </Col>
            <Col md={{ span: 2, offset: 3 }}>
              <Image rounded src={logo} fluid />
            </Col>
            <Col style={{ display: "flex", alignItems: "flex-end" }} md={{ span: 1, offset: 4 }}>
              <Button onClick={handleLogoutClick} style={{ float: "right", backgroundColor: "transparent", border: "0" }} className="pull-right">
                Logout
              </Button>
            </Col>
          </Row>
        </Container>
        <Container style={{ backgroundColor: "#555555", padding: "0" }} fluid className="wrapper">
          <Row className="content-row justify-content-mg-center">
            <Col lg="12" style={{ paddingLeft: "0", paddingRight: "0" }}>
              <Tabs style={{ backgroundColor: "#222222" }} defaultActiveKey="dashboard">
                <Tab eventKey="dashboard" title="Dashboard">
                  <Dashboard />
                </Tab>
                <Tab style={{ color: "#ffffff" }} eventKey="sessions" title="Sessions">
                  <MySessions />
                </Tab>
                <Tab tabClassName="shoveTabLeft" style={{ color: "#ffffff" }} eventKey="schedule" title="Schedule">
                  <Calendar refreshCallback={setCalendarUpdateCallback} />
                </Tab>
                <Tab tabClassName="bookdemo-tab" style={{ color: "#ffffff" }} eventKey="bookdemo" title="Book Demo">
                  <Demo calendarRefresh={calendarUpdateCallback} />
                </Tab>
                <Tab tabClassName="changepassword-tab" style={{ color: "#ffffff" }} eventKey="changepassword" title="Change Password">
                  <ChangePassword />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
        <HelpPopover></HelpPopover>
      </>
    );
  }
}

export default App;