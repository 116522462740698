import React, { useState, setState } from "react"
import { useTable, useFilters, usePagination, useSortBy } from "react-table"
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Table as BSTable, Image, Accordion, useAccordionButton } from "react-bootstrap";
import { BiFilterAlt, BiDownArrowAlt, BiUpArrowAlt, BiMinus } from 'react-icons/bi'
import "./table.css"
import left from "../content/chevron_left-white-18dp.svg"
import right from "../content/chevron_right-white-18dp.svg"
import leftend from "../content/first_page-white-18dp.svg"
import rightend from "../content/last_page-white-18dp.svg"

export default function UserTable({ columns, data }) {
    const [filterInput, setFilterInput] = useState("");
    const [username, setUsername] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [rank, setRank] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setUsername("");
        setFirstName("");
        setLastName("");
        setRank("");
    }
    const handleShow = () => setShow(true);

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilter("username", value);
        setFilterInput(value);
    };

    const handleDoubleClick = e => {
        var userId = data.filter(f => f.id == e.target.parentNode.firstElementChild.innerHTML)[0].id;
        getUserData(userId);
        handleShow();
    }

    const handleSubmit = (e) => { e.preventDefault(); alert("Sorry, this isn't implemented yet! :(") };

    const getUserData = (userId) => {
        var token = JSON.parse(sessionStorage.getItem("access_token"))?.access_token;
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        headers.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        }

        fetch(window.API_URL + "users/" + userId, requestOptions)
            .then(response => response.json())
            .then(result => {
                setUsername(result.username);
                setFirstName(result.firstname);
                setLastName(result.lastname);
                setRank(result.rank);
            });
    }

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    // Define a default UI for filtering
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
            />
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: {
            //hiddenColumns: ["id"]//, "rank"]
            sortBy: [{
                id: "lastname",
                desc: false
            }],
        }

    },
        useFilters,
        useSortBy,
        usePagination
    )

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {});
      
        return (
            <BiFilterAlt onClick={decoratedOnClick} style={{cursor: "pointer"}}/>
        );
      }

    return (
        <>
            <Row className="tablerow justify-content-mg-center" style={{ paddingBottom: "0px" }}>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            User
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" value={username} readOnly onChange={(e) => setUsername(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="firstname">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" value={firstname} onChange={(e) => setFirstName(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="lastname">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" value={lastname} onChange={(e) => setLastName(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="rank">
                                <Form.Label>Rank</Form.Label>
                                <Form.Control type="number" value={rank} onChange={(e) => setRank(e.target.value)} />
                            </Form.Group>
                            <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" type="submit">
                                Save Changes
                        </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Col lg="12">
                    <InputGroup >
                        <FormControl
                            value={filterInput}
                            onChange={handleFilterChange}
                            placeholder={"Search Username"}
                        />
                    </InputGroup>
                    <div className="mutedText">
                        Double-click a row to display more
                    </div>
                    <BSTable responsive striped bordered hover variant="dark" key={getTableProps()}>
                    <thead>
                            {headerGroups.map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            <Accordion>
                                                <Row>
                                                    <Col md="6"
                                                    {...header.getHeaderProps(
                                                                header.getSortByToggleProps()
                                                    )}>
                                                        {header.render('Header')}{header.canSort ? header.isSorted ? header.isSortedDesc ? <BiDownArrowAlt/> : <BiUpArrowAlt/> : <BiMinus/> : '\u00A0'} 
                                                    </Col>           
                                                    <Col md="6" style={{ float: "right", textAlign: "right" }}>
                                                    <CustomToggle eventKey='0'>
                                                            {header.canFilter ? <BiFilterAlt /> : null}
                                                    </CustomToggle>
                                                    </Col>
                                                </Row>
                                                <Accordion.Collapse eventKey="0">
                                                    <div className="filter-text">{header.canFilter ? header.render('Filter') : null}</div>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr onDoubleClick={handleDoubleClick} className="bodyrow" key={row.id}>
                                        {row.cells.map(cell => {
                                            return <td id={cell.id} key={cell.id}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </BSTable>
                </Col>
            </Row>
            <Row className="pagination justify-content-mg-center">
                <Col lg="4">
                    <Button size="lg" className="pageButton" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <Image src={leftend}></Image>
                    </Button>
                    <Button size="lg" className="pageButton" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <Image src={left}></Image>
                    </Button>
                    <Button size="lg" className="pageButton" onClick={() => nextPage()} disabled={!canNextPage}>
                        <Image src={right}></Image>
                    </Button>
                    <Button size="lg" className="pageButton" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <Image src={rightend}></Image>
                    </Button>
                </Col>
                <Col style={{ paddingTop: "15px" }} md={{ span: 2, offset: 2 }}>
                    Page{' '}

                    {pageIndex + 1} of {pageOptions.length}
                </Col>
                <Col md={{ span: 2, offset: 2 }}>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label style={{ paddingTop: "7px" }}>
                                Go to page:{' '}
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type=""
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}