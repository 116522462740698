import { Button, Form, Alert, Row, Col, Container } from "react-bootstrap";
import React, { useState } from "react";

export default function ChangePassword(resetToken) {

    const [newPassword, setNewPassword] = useState("");
    const [cNewPassword, setCheckNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    function validateForm() {
        return newPassword === cNewPassword;
    }

    function handleChangePassword(e) {
        e.preventDefault();

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");

        var raw = JSON.stringify({ "token": resetToken.resetToken, "new_password": newPassword });

        var requestOptions = {
            method: 'PATCH',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.API_URL + "users/password", requestOptions).then(data => {
            if (data.status !== 204) {
                setMessage("Invalid reset token");
                setMessageType("danger");
            }
            else {
                setMessage("Password Changed");
                setMessageType("success");
                setNewPassword("");
                setCheckNewPassword("");
                setTimeout(() => {
                    document.location.href = "/";
                }, 5000);
            }
        });
    }

    return (
        <Container className="Login">
            <Form size="lg" onSubmit={handleChangePassword}>
                <Form.Group size="lg" controlId="newPassword">
                    <Form.Label style={{ color: "#FFFFFF" }}>New Password</Form.Label>
                    <Form.Control
                        placeholder="Enter New Password..."
                        style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
                        required
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="checkNewPassword">
                    <Form.Label style={{ color: "#FFFFFF" }}>Confirm New Password</Form.Label>
                    <Form.Control
                        placeholder="Confirm New Password..."
                        style={{ color: "#FFFFFF", backgroundColor: "#222222", borderColor: "#222222" }}
                        required
                        type="password"
                        value={cNewPassword}
                        onChange={(e) => setCheckNewPassword(e.target.value)}
                    />
                </Form.Group>
                <br />
                <Row className="justify-content-md-center">
                    <Col >
                        <Button style={{ borderColor: "#A9AB2D", backgroundColor: "#A9AB2D" }} block={"true"} size="lg" type="submit" disabled={!validateForm()}>
                            Change Password
                    </Button>
                    </Col>
                </Row>
                {
                    message &&
                    <div>
                        <br />
                        <Alert className="text-center" variant={messageType}> {message} </Alert>
                    </div>
                }
            </Form>
        </Container>
    )
}