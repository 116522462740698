import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar  } from 'react-chartjs-2';
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
export class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
        this.chartReference = React.createRef();
    }

    render() {
        if (!this.props.data.datasets || this.props.data.datasets.length === 0) {
            // console.log("No data to render");
            return (<></>);
        }
        else {
            return (<Bar style={{width:"100%", height:"100%"}} ref={this.chartReference} data={this.props.data}  options={{
                indexAxis: this.props.indexAxis || 'x',
                color: "#FFFFFF",
                plugins:{
                    legend:{
                        display: !this.props.stacked,
                    }
                },
                scales: {
                    y: {
                        axis: 'y',
                        stacked: this.props.stacked,
                        ticks: {
                            color: "#FFFFFF"
                        }
                    },
                    x: {
                        axis: 'x',
                        stacked: this.props.stacked,
                        ticks: {
                            color: "#FFFFFF"
                        }
                    }
                }
            }} />)
        }
    }
}